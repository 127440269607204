<template>
  <div>
    <index-catalogue-component v-if="getStep=='searchCatalogue'" :motCle="motCle" :interface-type="getTab" @updateMotCle="updateMotCle"></index-catalogue-component>
    <produit-detail  v-if="getStep=='ProduitDetail'" :is-catalogue="true" :from-list="getFromList"></produit-detail>
    <index-assureur v-if="getStep=='AssureurDetail'" :is-catalogue="true" ></index-assureur>
    <formation-dda v-if="getStep=='FormationDetail'"></formation-dda>
    <partenaire-detail v-if="getStep=='PartenairesDetail'"></partenaire-detail>
    <WeedoITDetail v-if="getStep=='WeedoITDetail'"></WeedoITDetail>
    <EndyaDetail v-if="getStep=='EndyaDetail'"></EndyaDetail>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import IndexCatalogueComponent from './IndexCatalogue.vue'
import ProduitDetail from './ProduitDetail.vue'
import IndexAssureur from './IndexAssureur.vue'
import FormationCatalogueDetailComponent from './FormationCatalogueDetailComponent.vue'
import FormationDda from './formation_dda/FormationDda.vue'
import PartenaireDetail from './PartenaireDetail.vue'
import WeedoITDetail from './WeedoITDetail.vue'
import EndyaDetail from './EndyaDetail.vue'


export default {
    components:{IndexCatalogueComponent,ProduitDetail,IndexAssureur,FormationCatalogueDetailComponent,PartenaireDetail,WeedoITDetail,EndyaDetail,FormationDda, FormationDda},
    created:function(){
        if(this.$route.params.formation_dda && this.$route.params.formation_dda == "formation-dda"){
            this.$store.commit("setStep", {
                    step: 'FormationDetail',
                });
        }
    },
    data() {
        return {
            motCle:null
        }
    }, 
    computed: {
        ...mapGetters({getStep : "getStep", getFromList: "getFromList", getTab : "getTab",}),
    },
    beforeDestroy() {
        this.$store.commit("setFromList", true);
        this.$store.commit("setStep", {step: 'searchCatalogue'});
    },
    methods: {
        checkTypeSearch(value){},
        updateMotCle(value){
            this.motCle=value
        }
    }
}
</script>
