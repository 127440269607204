<template>
    <div>
        <div class="vue-form-wizard mb-3 md">
            <b-overlay :show="showLoading" no-wrap />
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-1 mt-1">
                    <h1 class="text-primary font-weight-bolder  ml-1" >Catalogue</h1>
                </div>
                    <div class="mb-1" v-if="currentUser.courtier_user[0].isPrincipal || currentUser.courtier_user[0].is_interlocuteur">
                        <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        size="md"
                        class="ml-1 mt-1"
                        @click="$router.push({name: 'fiche-demande-ouverture-code'})"
                        >
                        <!-- <feather-icon icon="PlusIcon" /> -->
                        <span class="align-middle text-uppercase">Demande d'ouverture de code</span>
                    </b-button>
                </div>
            </div>

            <b-row style="padding-right: 1rem; padding-left: 1rem;" class="m-1">
                <b-col md="3" @click="changeInterface('assureur')" >
                    <label class="btn btn-outline-primary  box-custom-modale-child w-100 custom-label" :class="interfaceType=='assureur' ? 'btn-primary' : ''" >
                        <span>
                            Assureurs
                        </span>
                    </label>
                </b-col>
                
                <b-col md="3" @click="changeInterface('produit')">
                    <label class="btn btn-outline-primary  box-custom-modale-child w-100 custom-label" :class="interfaceType=='produit' ? 'btn-primary' : ''" >
                        <span>
                            Produits
                        </span>
                    </label>
                </b-col>

                <b-col md="3" @click="changeInterface('partenaires')">
                    <label class="btn btn-outline-primary  box-custom-modale-child w-100 custom-label" :class="interfaceType=='partenaires' ? 'btn-primary' : ''" >
                        <span>
                            Partenaires
                        </span>
                    </label>
                </b-col>

                <b-col md="3" @click="changeInterface('formation')">
                    <label class="btn btn-outline-primary  box-custom-modale-child w-100 custom-label" :class="interfaceType=='formation' ? 'btn-primary' : ''" >
                        <span>
                            Formations DDA
                        </span>
                    </label>
                </b-col>

                <!-- <b-col md="3" @click="changeInterface('demande-code')" >
                    <label class="btn btn-outline-primary  box-custom-modale-child w-100 custom-label" :class="interfaceType=='demande-code' ? 'btn-primary' : ''" >
                        <span>
                            Demande code
                        </span>
                    </label>
                </b-col> -->
            
            </b-row>

            <b-row style="padding-right: 1rem; padding-left: 2rem;" class="m-1 no-gutters" v-show="interfaceType=='assureur'">
                <b-col v-for="assureur in assureurs" :key="assureur.id" md="3" @click="changeAssureur(assureur.id)" class="d-flex align-items-stretch">
                    <label class="btn btn-outline-primary  box-custom-modale-child w-100 custom-label py-3"  >
                            <img :src="assureur.assureur_image" alt="logo" class="img-fluid logo-assureur">
                            
                            <span>
                            {{ assureur.nom_com_assureur }}
                            </span>

                        
                    </label>
                </b-col>
                <!-- <b-col md="12">
                    <div class="mb-2 img-proche">
                        <img class="img-fluid shadow-lg" src="@/assets/images/Prochainement.png" />
                    </div>
                </b-col> -->
            </b-row>

            <b-row style="padding-right: 1rem; padding-left: 1rem;" class="m-1" v-show="interfaceType=='produit'">
                <b-col v-for="risque in risques" :key="risque.value" md="3" @click="changeRisque(risque.value)">
                    <label class="btn btn-outline-primary  box-custom-modale-child w-100 custom-label py-3"  >
                        <span>
                            {{ risque.text }}
                        </span>
                    </label>
                </b-col>
                <!-- <b-col md="12">
                    <div class="mb-2 img-proche">
                        <img class="img-fluid shadow-lg" src="@/assets/images/Prochainement.png" />
                    </div>
                </b-col> -->
            </b-row>

            <b-row style="padding-right: 1rem; padding-left: 1rem;" class="m-1" v-show="interfaceType=='partenaires'">
                <b-col md="3" @click="changePartnaire('PartenairesDetail')">
                    <label class="btn btn-outline-primary  box-custom-modale-child w-100 custom-label py-3">
                        <img alt="logo" class="img-fluid logo-partenaire" src="../../../public/Anne.png" />
                        <span>
                            ANNE-JULIE GIRAUD
                        </span>
                    </label>
                </b-col>
                <!-- <b-col md="12">
                    <div class="mb-2 img-proche">
                        <img class="img-fluid shadow-lg" src="@/assets/images/Prochainement.png" />
                    </div>
                </b-col> -->
                <b-col md="3" @click="changePartnaire('WeedoITDetail')">
                    <label class="btn btn-outline-primary  box-custom-modale-child w-100 custom-label py-3">
                        <img alt="logo" class="img-fluid logo-partenaire" src="../../../public/Weedoit.webp" />
                        <span>
                           WEEDO IT
                        </span>
                    </label>
                </b-col>
                <b-col md="3" @click="changePartnaire('EndyaDetail')">
                    <label class="btn btn-outline-primary  box-custom-modale-child w-100 custom-label py-3">
                        <img alt="logo" class="img-fluid logo-partenaire" src="../../../public/Endya.png" />
                        <span>
                           ENDYA
                        </span>
                    </label>
                </b-col>
            </b-row>
                

            <b-row v-show="interfaceType=='formation'">
                <b-col md="12">
                    <b-row class="row mb-2">
                        <div class="col">
                            <h4 class="text-primary font-weight-bolder  ml-1" >MES FORMATIONS DDA</h4>
                        </div>
                        <div class="col-auto">
                        <b-button
                            block
                            variant="outline-primary"
                            @click="backStep"
                        >
                        <feather-icon 
                        icon="ArrowLeftIcon"
                        class="mr-50"
                        />
                            REVENIR À LA LISTE
                        </b-button>
                        </div>
                    </b-row>

                </b-col>
            </b-row>

        </div>
        <!-- <div class="mb-2 img-proche">
            <img class="img-fluid shadow-lg" src="@/assets/images/Prochainement.png" />
        </div> -->
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol,BButton,BCard,BDropdown, BDropdownItem,BFormCheckbox,BFormCheckboxGroup,BOverlay
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
export default {
    components:{
    Ripple, 
    BOverlay,  
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BCard,
    BDropdown, 
    BDropdownItem,
    BFormCheckboxGroup,
    BFormCheckbox},
    directives: {
        Ripple
    },
    created:function(){},
    props: {
        interfaceType: {
            type: String,
            default: 'assureur',
            required: false
        }
    },
    data() {
        return {
        currentUser: JSON.parse(localStorage.getItem('userData')),
        produitType:null,
        risques:[],
        assureurId:null,
        profil: [
            { text: "Assureurs", value: "assureur" },
            { text: "Produits", value: "produit" },
            { text: "Formations", value: "formation" },
            { text: "Partenaires", value: "partenaires" },

        ],
        assureurs:[],
        produitValue:'',
        assureurValue:null,
        showLoading:false
        }
    }, 
    computed: {
        ...mapGetters(["getStep"]),
    },
    created() {
        this.getAllRisques();
        this.getAllAssureurs();
    },
    methods: {
        changePartnaire(step){
            this.$store.commit("setStep", {
                    step: step,
                });
        },
        getAllRisques() {
            this.$http.get(`risques/getAllRisquesHasProduits`)
            .then((res) => {
                this.risques = []
                Array.from(res.data).forEach(risque => {
                    this.risques.push({
                        text: risque.label,
                        value: risque.id
                    })
                }
                );
            })
            .catch((e) => {
                console.log(e);
            })
        },
        getAllAssureurs() {
            this.showLoading = true
            this.$http.get(`assureurs/getAssureursByIds`)
            .then((res) => {
                this.assureurs = res.data
                this.showLoading = false
                this.sortAssureur();
            })
            .catch((e) => {
                console.log(e);
                this.showLoading = false
            })
        },
        sortAssureur() {
            const order = ['ADP', 'ADP2', 'IARD'];
            const adpAssureur = this.assureurs.filter(assureur => assureur.produits[0].risque.type === 'ADP');
            adpAssureur.sort((a, b) => a.nom_com_assureur.localeCompare(b.nom_com_assureur));
            adpAssureur.sort((a, b) => b.produits[0].risque.prefixe.localeCompare(a.produits[0].risque.prefixe));

            const nonAdpAssureur = this.assureurs.filter(assureur => assureur.produits[0].risque.type !== 'ADP')
            nonAdpAssureur.sort((a, b) => order.indexOf(a.produits[0].risque.type) - order.indexOf(b.produits[0].risque.type));

            this.assureurs = [...adpAssureur, ...nonAdpAssureur];
        },
        changeRisque(idRisque) {
            this.showLoading = true
            let data = {
                type: 'risque',
                data: null
            }
            this.$http
                .get(`risques/findRisqueById/${idRisque}`)
                .then((r) => {
                    data.data = r.data
                    console.log(data)
                    this.$store.commit("setStep", {step: 'ProduitDetail'});
                     this.$store.commit("setFromList", true);
                    this.$store.commit("setRisque", {risque: data});
                    this.showLoading = false
                }).catch((err) => {
                    console.log(err)
                    this.showLoading = false
                });
        },
        changeAssureur(id){
            this.assureurId=id
            let data={
                type:'assureur',
                risque:null,
                data: {assureurId: id}
            }
            this.$store.commit("setObjet", {
                objet: data,
            });

            this.$store.commit("setStep", {
                step: 'AssureurDetail',
            });
        },
        changeInterface(val){
            console.log(val)
            
            if(val=='formation'){
                this.$store.commit("setStep", {
                    step: 'FormationDetail',
                });
            }
            else{
                this.$store.commit("setTab", {tab: val});
            }
        },
        backStep(){
            this.showLoading=true
            this.$store.commit("setStep", {
                step: 'searchCatalogue',
            });
            this.showLoading=false
        },
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/pages/page-pricing.scss';

#box-custom-modale.btn-group,#box-custom-modale-sm {
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
  width: 100%;
}

#box-custom-modale > .box-custom-modale-child {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
    background-color: #fff;
}

#box-custom-modale-sm > .box-custom-modale-child {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
    background-color: #fff;
}
#box-custom-modale > .box-custom-modale-child.active {
  background-color: #4d25bc;
  color: #fff;
}
#box-custom-modale > .box-custom-modale-child.active {
  background-color: #4d25bc;
  color: #fff;
}
#box-custom-modale-sm .btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: #4d25bc;
  color: #fff;
  transition: 0.4s;
}
.custom-label{
    margin: 1rem;
    padding: 25px 15px;
}

.img-proche
{
    padding:5% 10%;
}
.logo-assureur {
    width: 175px;
    height: 120px;
    display: block;
    margin: 0px auto 15px;
}
.logo-partenaire{
    width: 175px;
    display: block;
    height: 140px;
    margin: 0px auto 15px;

}
@media(max-width:768px)
{
    .img-proche
    {
        margin-top:50px;
        padding: 0;
    }
}
// #box-custom-modale-sm .btn-outline-primary:hover:not(.disabled):not(:disabled) {
//   background-color: #4d25bc;
//   color: #fff;
//   transition: 0.4s;
// }
</style>
